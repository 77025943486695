import {
  Configuration,
  DictionaryApi,
  FactoryApi,
  FoodLogApi,
  NotificationsApi,
  PopbiteApi,
  PoppinApi,
  TutorialApi,
  UserApi,
} from '@poppin/sdk'
import axios from 'axios'
import { getToken, handleUnauthorized } from './utils'
if (!process.env.NEXT_PUBLIC_BASE_URL)
  throw new Error('NEXT_PUBLIC_BASE_URL is not set')

const axiosInstance = axios.create()

const onSuccess = (res: unknown) => res
axiosInstance.interceptors.response.use(onSuccess, (e) => {
  if (!axios.isAxiosError(e)) throw e
  if (e.response?.status === 403) {
    handleUnauthorized()
    return
  }
  throw e
})

export async function setupApi() {
  const token = await getToken()
  const basePath = process.env.NEXT_PUBLIC_BASE_URL

  const config = new Configuration({
    baseOptions: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })
  const poppinApi = new PoppinApi(config, basePath, axiosInstance)
  const userApi = new UserApi(config, basePath, axiosInstance)
  const popbiteApi = new PopbiteApi(config, basePath, axiosInstance)
  const foodLogApi = new FoodLogApi(config, basePath, axiosInstance)
  const notificationApi = new NotificationsApi(config, basePath, axiosInstance)
  const factoryApi = new FactoryApi(config, basePath, axiosInstance)
  const dictionaryApi = new DictionaryApi(config, basePath, axiosInstance)
  const tutorialApi = new TutorialApi(config, basePath, axiosInstance)
  return {
    poppinApi,
    userApi,
    popbiteApi,
    foodLogApi,
    notificationApi,
    factoryApi,
    dictionaryApi,
    tutorialApi,
  }
}
