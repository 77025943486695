import { Auth } from 'aws-amplify'
import Router from 'next/router'

export function handleUnauthorized() {
  Router.replace('/register')
}

export async function getToken() {
  const session = await Auth.currentSession()
  return session.getAccessToken().getJwtToken()
}
